$transition-animation-function: cubic-bezier(0.22, 1, 0.36, 1);

.dropdown-menu {
  transition: opacity 0.4s $transition-animation-function;
  z-index: 999;

  .kt-nav {
    padding: 0;
  }

  .kt-datatable__pager-info & {
    padding: 4px 0;
    // margin: 0 !important;
  }
}

.kt-subheader {
  .dropdown {
    display: inline-flex;
  }
}