@import './mixins';

.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell > span {
  color: #222;
}

.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span, .kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span, .kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span {
  background-color: #5d78ff;
}

.kt-datatable__cell {
  .kt-switch input:checked ~ span:before,
  .kt-switch input:checked ~ span:after {
    box-shadow: none;
  }

  &--check {
    width: 30px;
    padding-left: 0 !important;

    .kt-checkbox > span {
      left: 6px !important;
    }
  }
}

.kt-datatable__row--clickable {
  cursor: pointer;
}

.kt-datatable__row--hidden {
  display: none !important;
}

.kt-datatable__action-buttons {
  @include flex_row_nowrap($justify: center);
  // .btn {
  //   flex-shrink: 0;
  //   flex-grow: 0;
  // }
  .btn.btn-clean[disabled] {
    cursor: default;
    color: #93a2dd;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
      i {
        color: #93a2dd;
      }
    }
  }
}

.kt-datatable.kt-datatable--default {
  .kt-datatable__pager {
    &-size {
      > .btn.dropdown-toggle {
        display: flex;
      }
    }
  }
}

.kt-datatable__cell.kt-datatable__cell--center[data-field="actions"] > span {
  display: inline-block !important;
}

.kt-datatable__cell.kt-datatable__cell--actions > span {
  display: inline-block !important;
}

.kt-datatable__group-header {
  margin-bottom: 1px !important;
}

.kt-datatable__group-header-cell {
  padding: 8px 16px;
  font-weight: bold;
  background-color: #e7e6f1;
  color: #726aca;
  .kt-datatable__group-icon {
    font-size: 1.4rem;
    margin-right: 5px;
    text-decoration: none;
    color: #726aca;
    > i {
      font-weight: bold;
    }
  }
}

.kt-datatable__group-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kt-datatable__group-header-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.kt-datatable__group-header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.kt-datatable__cell-detail-trigger {
  text-align: center;
  font-size: 1.2rem;
}


.kt-datatable__cell--detail {
  padding: 0 !important;
}

.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell:last-child, .kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell:last-child, .kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell:last-child {
  padding-right: 0;
}

.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell {
  background-color: #3A3A51;
  > span {
    color: #fff;
  }
}