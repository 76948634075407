@import '../../../assets/scss/mixins';

.inspection-site-inventory {
  padding: 15px 20px;

  @at-root {
    &__category-node {
      > .tree__node-it {
        > .tree__node-text {
          a {
            color: #646c9a;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }

    &__empty-tree {
      @include flex-row-nowrap($justify: flex-start);
      .icon {
        margin-right: 8px;
        font-size: 1.2em;
      }
    }

    &__site-node {
      > .tree__node-it {
        > .tree__node-text {
          a {
            color: #646c9a;
          }
        }
      }
    }

    &__item-node {
      > .tree__node-it {
        > .tree__node-text {
          a {
            color: #646c9a;
          }
        }
      }
    }

    &__option-node {
      > .tree__node-it {
        > .tree__node-text {
          a {
            color: #444;
            font-size: 0.95em;
          }
        }
      }
    }
  }
}

.option-editor__value-label {
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #ea89b8;
  margin: 0 5px;
  color: #fff;
}

.inventory-summary {
  &__page-body {
    padding: 0 !important;
  }

  &__loading {
    padding: 32px;
    text-align: center;
  }

  &__table {
    table-layout: auto;
    width: 100%;
  }

  &__site-icon {
    text-align: center;
    > img {
      width: 32px;
      height: 32px;
    }
  }

  $border-color: #a4a2ab;

  &__col-header {
    font-weight: bold;
    border: 1px solid $border-color;
    border-bottom-width: 2px;
    padding: 20px 8px;
    background-color: #abaec5;
    white-space: nowrap;
    color: #332840;
  }

  &__cell {
    border: 1px solid $border-color;
    padding: 8px;
    background-color: #fff;
  }

  &__site-code {
    text-align: center;
  }

  &__site-desc {
    max-width: 150px;
  }

  &__option-desc {
    max-width: 200px;
  }

  &__tool-name {
    max-width: 100px;
    text-align: center;
  }

  &__tool-quantitative-type {
    max-width: 100px;
    text-align: center;
  }

  &__option-result {
    white-space: nowrap;
  }

  &__option-name {
    max-width: 150px;
  }
}

.inspection-site-icon {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  > img {
    width: 32px;
    height: 32px;
  }

  > svg {
    width: 32px;
    height: 32px;

    path {
      fill: #555;
    }
  }
}

.inventory-manager {
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__head
    .kt-datatable__row
    > .kt-datatable__cell {
    background-color: #3a3a51;
    > span {
      color: #fff;
    }
  }

  .kt-subheader {
    height: auto !important;

    .btn {
      white-space: nowrap;
    }

    .form-control {
      width: 128px;
    }

    > .kt-container .kt-subheader__toolbar {
      flex: 1;
      > .kt-subheader__wrapper {
        flex: 1;
        margin-left: -4px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .kt-subheader__main {
      margin-right: 20px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}

.kt-datatable.kt-datatable--default.kt-datatable--loaded {
  > .kt-datatable__table {
    > .kt-datatable__body .kt-datatable__row {
      &--detail-expanded {
        border-bottom: none;
      }

      &.kt-datatable__detail-row {
        border-bottom: none;
      }
    }
  }
}

.inspection-site-icon {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 auto;

  > img {
    width: 32px;
    height: 32px;
  }

  > svg {
    width: 32px;
    height: 32px;

    path {
      fill: #555;
    }
  }
}
