@import '../../../../assets/scss/mixins';

.quotation-tpl-item {
  $item: &;

  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__check {
    margin-bottom: 0 !important;
    width: 18px;
    height: 18px;
    position: relative;
    top: -3px;
    padding-left: 26px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ddd;
    padding: 10px 15px;

    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    #{$item}--editable & {
      cursor: move;
    }
  }

  &__sort-handle {
    cursor: move;
    @include no-select();
  }

  &__body {
    padding: 10px 15px;
    border: 1px solid #eee;

    > h5 {
      font-size: 1rem;
      font-weight: bold;
      color: #8f8989;
    }
  }
  &__no-materials {
    font-style: italic;
    color: #bbb;
    padding: 4px 0;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: 900;
    margin-bottom: 0;
  }

  &__code {
    font-weight: 100;
    color: #bbb;
  }

  &__man-hour-cost {
    margin-right: 1rem !important;
  }

  &__man-hour-cost-value {
    font-weight: 300;
    font-size: 1rem;
    del {
      color: red;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;

    i.la {
      font-size: 1.5rem;
    }

    dl,
    dt,
    dd {
      display: inline-block;
      margin: 0;
    }
  }
  &__material-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    &-hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__material {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    &-check {
      margin-bottom: 0 !important;
      width: 18px;
      height: 18px;
      position: relative;
      top: -1px;
      padding-left: 26px;
    }

    &-content {
      flex: 1;
      > h6 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    &-actions {
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 4px;
      gap: 8px;

      > a {
        text-decoration: none;
        color: #888;
        &:hover {
          color: #5867dd;
        }
        .la {
          font-size: 20px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        cursor: move;
        @include no-select();
        path {
          fill: #888;
        }
      }
    }

    > h6 {
      font-weight: 700;
      margin-bottom: 0.35rem;
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      margin-right: 1rem;
      color: #555;
      font-size: 0.9rem;
      > label {
        color: #aaa;
        &:after {
          content: ':';
          margin-right: 5px;
        }
      }
    }

    &-type.kt-badge {
      min-height: 0;
      padding: 0px 7px;
      border-radius: 20px;
      font-size: 0.7rem;
      line-height: 15px;
      margin-left: 0.5rem;
    }

    &-price-qty {
      margin-left: 1rem;
    }

    &-price {
      font-weight: 300;
      font-size: 1rem;
    }

    &-price-x {
      color: #aaa;
      margin: 0 0.35rem;
    }

    &-qty {
      font-weight: 900;
      font-style: italic;
    }
  }
}
