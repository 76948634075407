@import '../../assets/scss/mixins';

.list-toolbar__filter {
  .task-list-toolbar__store-picker-wrapper {
    .store-picker__component {
     min-width: 160px;
     max-width: 160px;
   }
  }
}

.task-detail {
  padding: 0 15px;

  dl {
    @include flex-row-nowrap($justify: flex-start, $alignItems: flex-start);
    margin-bottom: 0;
    line-height: 2.4;
    border-bottom: 1px solid #ddd;

    dt, dd {
      margin: 0;
    }
    dt {
      width: 100px;
      margin-right: 8px;
      text-align: right;
    }

    dd {
      .kt-badge {
        line-height: 1.5;
      }
    }
  }
}