.acl-settings__actions {
  p {
    margin-bottom: 0;
  }
}

.visible-store-settings {
  padding: 1rem;
  @at-root {
    &__tip {
      margin-bottom: 1rem;
    }
    &__store {
      background-color: #f7f7f7;
      padding: 0.5rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      &--selected {
        background-color: #3f51b5;
        color: #fff;
      }
    }
  }
}
