.dc-tpl-detail {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 600px;
  $detail: &;
  $errorbg: 'data:image/gif;base64,R0lGODdhBAADAPEAANv///8AAP///wAAACwAAAAABAADAEACBZQjmIAFADs=';
  // $errorbg: 'https://i.imgur.com/HlfA2is.gif';

  .m-checkbox {
    white-space: nowrap !important;
    margin-bottom: 0;
  }

  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    &--error {
      background-image: url($errorbg);
      background-repeat: repeat-x;
      background-position: 0 100%;
      background-attachment: scroll;
      &:focus {
        background-image: none;
      }
    }
    &:read-only,
    &[readonly],
    &[readOnly] {
      border: none;
    }
  }

  @at-root {
    #{$detail}__item-list {
      $item-list: &;
      display: block;
      width: 100%;
      padding: 0;
      &--empty {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @at-root {
    #{$detail}__item {
      display: block;
      padding: 20px;
      margin-bottom: 0;
      width: 100%;
      background-color: rgba(63, 81, 181, 0.03);

      $item: &;

      .form-control {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }

      &:nth-child(2n) {
        background-color: #fff;
      }

      .kt-switch > label {
        margin-bottom: 0;
      }

      @at-root {
        #{$item}-hd {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          width: 100%;
        }

        #{$item}-sort {
          cursor: move;
          height: 20px;
          .svg--inline {
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            > svg {
              width: 20px;
              height: 20px;

              path {
                fill: #888;
                #{$item}-sort:hover & {
                  fill: #333;
                }
              }
            }
          }
        }

        #{$item}-subject {
          display: flex;
          flex: 1;
          .form-control {
            font-weight: bold;
            flex: 1;
            font-size: 1.35rem;
            margin: 0 1rem;
            height: auto;
            padding: 1rem 1rem;
            border-bottom-width: 2px;
          }
        }

        #{$item}-requires-photo {
          white-space: nowrap;
        }

        #{$item}--being-dragged {
          background-color: #ddd;
          display: block !important;
          width: 100%;
          cursor: move;
        }

        #{$item}-actions {
          margin-left: 1rem;
          > a {
            color: #888;
            text-decoration: none;
            margin-left: 5px;
            &:hover {
              color: #716aca;
            }
            i {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    #{$detail}__option-list {
      width: 100%;
      > table {
        th,
        td {
          padding: 2px 4px;
          vertical-align: middle;
        }
      }

      &--readonly table th {
        border-bottom: 1px solid #ddd;
      }

      &--empty {
        > div {
          padding: 1rem 0;
          font-size: 0.9rem;
          color: #aaa;
          > span {
            padding: 0.5rem 0;
          }
        }
      }

      &--empty-with-error > div > span {
        background-image: url($errorbg);
        background-repeat: repeat-x;
        background-position: 0 100%;
        background-attachment: scroll;
      }
    }

    #{$detail}__option {
      $option: &;

      @at-root {
        #{$option}-col-title {
          width: 158px;
          .form-control {
            width: 150px;
          }
        }

        #{$option}-col-remark {
          width: 408px;
          .form-control {
            width: 400px;
          }
        }

        #{$option}-col-default-check {
          text-align: center;
        }

        #{$option}-col-is-expected {
          text-align: center;
        }

        #{$option}-col-actions {
          text-align: center;

          > a {
            color: #888;
            text-decoration: none;
            margin-left: 5px;
            &:hover {
              color: #716aca;
            }
            i {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
