.mp-template-settings-summary__industry-settings {
  display: table;
  &-industry-info {
    display: table-row;
    margin: 0 0 10px 0;
    padding: 0;

    dt, dd {
      display: table-cell;
      vertical-align: middle;
      margin: 0;
      padding: 8px;
    }

    dt {
      text-align: right;
    }

    dd {
      .kt-badge, .btn {
        margin-right: 8px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }
}

.mp-template-settings-page {
  &__content {
    .kt-datatable__table, .kt-block {
      min-height: 0 !important;
    }

    .kt-datatable__empty {
      min-height: 100px !important;
    }
  }

  &__list-title {
    font-size: 1.2rem;
    border-bottom: 1px solid #f0f3ff;
    padding: 15px;
    background-color: #fafafa;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.mp-template-detail {
  display: table;
  padding: 0 15px;

  &__info {
    display: table-row;
    >dt {
      display: table-cell;
      width: 1%;
      white-space: nowrap;
      vertical-align: top;
      padding: 8px;
      text-align: right;
    }
    >dd {
      display: table-cell;
      vertical-align: top;
      padding: 8px;
    }
  }

  &__scenes {
    width: 100%;
  }

  &__params {
    margin: 0;
    padding: 0;
    display: table;
    border-collapse: collapse;
    width: 100%;
    > li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: table-row;
      > :first-child {
        text-align: right;
      }
      > * {
        display: table-cell;
        padding: 8px;
        border: 1px solid #eee;
      }
    }
  }

  &__actions {
    margin-top: 15px;
    padding: 0 30px;
  }
}