.btn.btn-selectable {
  user-select: text !important;
}

.show > .btn.btn-clean[disabled],
.btn.btn-clean.active[disabled],
.btn.btn-clean:active[disabled],
.btn.btn-clean:hover[disabled] {
  background-color: transparent;
  color: #93a2dd;
  cursor: no-drop;
  i {
    color: #93a2dd;
  }
}