@import './mixins';

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#application {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.m-subheader .m-subheader__title {
  font-family: 'Segoe UI', 'Microsoft YaHei', Roboto, Poppins;
}

.page-title-text {
  .refresh-btn {
    text-decoration: none;
    margin-left: 8px;
    color: #888;
    &:hover {
      color: #5867dd;
    }
  }
}

.m-quick-sidebar {
  width: auto;
}

.restricted-no-rights {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  &--default-content {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    color: #aaa;
    margin: 0 auto;
    text-align: center;

    .icon {
      font-size: 5rem;
      color: #ddd;
      margin-bottom: 1rem;
    }
  }
}

.m-checkbox > input.indeterminate ~ span::after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  border: 0px solid #aaa;
  background-color: #aaa;
  transform: none;
}

.m-quick-sidebar.m-quick-sidebar--on {
  right: 0 !important;
}

.m-card-user__pic {
  .drop-zone {
    border: none;
  }
}

.list-toolbar__filter {
  .store-picker {
    @include flex-row-nowrap();
    @at-root {
      &__component {
        min-width: 250px;
        margin-right: 8px;
      }
    }
  }
}

.m-checkbox > input:disabled ~ span:after {
  border-color: #bdc3d4 !important;
}

.kt-quick-panel__nav-item {
  .nav-link.active {
    font-weight: bold !important;
  }
}

.form-text.text-muted {
  ul {
    margin-left: 1rem;
    padding-left: 1rem;
  }
}

.top-store-select {
  &__org-name {
    font-size: 1.6rem;
    color: #2e2e61;
    font-weight: bolder;
  }
}

.kt-portlet__head-refresh {
  margin-left: 5px;
  font-size: 1.2rem;
}

.kt-header {
  .kt-header-menu {
    .kt-menu__nav {
      .kt-menu__subnav {
        max-height: 50vh;
        overflow-y: auto;
      }
      > .kt-menu__item {
        &--active.top-store-select {
          > .kt-menu__link {
            background-color: #fff;
            &:hover {
              background-color: rgba(77, 89, 149, 0.06);
            }
          }
        }
      }
    }
  }
}

.kt-portlet__body--no-padding {
  padding: 0 !important;
}

.kt-quick-panel {
  > .kt-block {
    display: flex;
    flex-direction: column;
  }

  .kt-quick-panel__content {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
}

.common-detail-view {
  padding: 0 15px;

  > h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: gray;
  }

  > dl {
    @include flex-row-nowrap($justify: flex-start, $alignItems: flex-start);
    margin-bottom: 0;
    line-height: 2.4;
    border-bottom: 1px solid #ddd;

    dt,
    dd {
      margin: 0;
    }

    dt {
      width: 25%;
      margin-right: 8px;
      text-align: right;
      &::after {
        content: ': ';
      }
    }

    dd {
      .kt-badge {
        line-height: 1.5;
      }
    }
  }
}
