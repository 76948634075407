.predefined-templates-preview {
  $preview: &;

  display: flex;
  justify-content: flex-start;

  $icon-width: 150px;

  @at-root {
    #{$preview}__item {
      margin: 15px;
      &:first-child {
        margin-left: 0;
      }
      svg {
        width: $icon-width - 30px;
        height: $icon-width - 30px;
      }
    }

    #{$preview}__item-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f6f5f7;
      border-radius: 30px;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      #{$preview}__item--active &,
      &:hover {
        background-color: #e2dcee;
      }
    }

    #{$preview}__icon {
      width: $icon-width;
      height: $icon-width;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #{$preview}__label {
      text-align: center;
      margin-bottom: 1rem;
      font-weight: bold;
      padding-left: 15px;
      padding-right: 15px;
    }

    #{$preview}__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;
    }

    #{$preview}__action-link {
      text-decoration: none !important;
      margin-left: 8px;
    }
  }
}

.kt-subheader .btn.btn-import-template {
  width: 25px !important;
  height: 25px !important;
}
