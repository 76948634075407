.btn-outline-secondary {
  color: #888;
}

.stat-aspect-list {
  display: flex;
  justify-content: stretch;
}

.stat-aspect-item {
  flex: 1;
  border-right: 1px solid #ebedf2;
  border-bottom: 1px solid #ebedf2;
  position: relative;
  cursor: pointer;
  &:last-child {
    border-right: none;
  }

  > div {
    padding: 24px 16px;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 600;
  }
}

.stat-aspect-item--active::before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #716aca;
}

.stat-aspect-item__value {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 10px 0;
}

.stat-aspect-item__dist {
  width: 200px;
  height: 0;
  position: absolute;
  right: -32px;
  top: 24px;
}

.dashboard-top-right-options {
  display: flex;
  align-items: center;

  .btn.btn-secondary:hover:not(:disabled),
  .btn.btn-secondary:focus {
    background-color: #fdfdfd;
  }
}

.stats-by-time__header {
  display: flex;
  justify-content: space-between;
  padding: 16px 8px 8px 16px;

  > div:first-child {
    display: flex;
    align-items: center;
    >div:first-child {
      margin-right: 16px;
    }
  }
}

.dashboard-section {
  padding: 16px 0;

  h2 {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0 16px;
  }
}

.dashboard-section .kt-datatable__empty {
  padding: 32px 16px;
}