.construction-tpl-man {
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__side-panel {
    > h2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__side-invalid-indicator {
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f44336;
    > i {
      font-size: 0.8rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &-msg {
      color: #f44336;
      font-size: 0.85rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      i {
        margin-right: 0.25rem;
      }
    }
    .btn {
      margin-left: 1rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__sidebar {
    width: 16.7%;
    padding-bottom: 1rem;
    > div > h2 {
      font-size: 1rem;
      font-weight: bold;
      color: #aaa;
      padding: 0 2rem;
      margin-top: 1rem;
    }
  }

  &__category-list {
    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      $item: &;

      a {
        text-decoration: none;
      }

      &--selected {
        background-color: #ddd;
        #{$item}#{'-name'} {
          font-weight: bold;
          color: #5867dd !important;
        }
      }

      &-name {
        display: block;
        padding: 0.75rem 2rem;
        font-size: 1.1rem;
        text-decoration: none;
        color: #111;
        flex: 1;

        > em {
          font-style: normal;
          font-size: 0.9rem;
          color: #aaa;
          margin-left: 0.5rem;
        }
      }

      &-remove {
        visibility: hidden;
        padding: 0 0.5rem;
        font-size: 1.3rem;
      }

      &:hover {
        #{$item}#{'-remove'} {
          visibility: visible;
        }
      }
    }
  }

  &__template-list {
    &-item {
      $item: &;

      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      &--selected {
        background-color: #ddd;
        #{$item}#{'-name'} {
          font-weight: bold;
          color: #5867dd !important;
        }
      }

      &-name {
        display: block;
        padding: 0.75rem 2rem;
        font-size: 0.9rem;
        text-decoration: none;
        color: #111;
        flex: 1;

        > em {
          font-style: normal;
          font-size: 0.75rem;
          color: #aaa;
          margin-left: 0.5rem;
        }
      }

      &-remove {
        visibility: hidden;
        padding: 0 0.5rem;
        font-size: 1.3rem;
      }

      &:hover {
        #{$item}#{'-remove'} {
          visibility: visible;
        }
      }
    }
  }

  &__procedure,
  &__procedure-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__procedure {
    margin-bottom: 1rem;

    &-info {
      flex: 1;
      margin: 0 0.5rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      overflow: hidden;

      input[type='text'] {
        flex: 1;
        appearance: none;
        outline: none;
        padding: 0.5rem 1rem;
        color: #777;
        border: none;
        font-weight: bold;
        &:focus {
          border-color: #5867dd;
          color: #000;
        }
        &::placeholder {
          color: #ccc;
        }
      }

      select {
        // appearance: none;
        outline: none;
        text-align: right;
        border: none;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-weight: 200;
        color: #777;
        font-size: 0.8rem;
        option {
          text-align: right;
        }
      }
    }

    &-sort-handle {
      cursor: move;
    }

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #888;
      }
    }

    &-remove {
      font-size: 1.5rem;
      text-decoration: none !important;
    }
  }

  &__no-procedures {
    font-size: 0.9rem;
    margin: 1rem 0;
    color: #aaa;
  }

  &__detail {
    flex: 1;
    padding: 1rem 2rem;
    border-left: 1px solid #ccc;
    &--no-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 200px;
    }

    > h2 {
      font-weight: bolder;
    }

    > dl {
      margin-bottom: 1.5rem;
      > dt {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }

  @at-root {
    &__group {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      $item: &-item;

      ul,
      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      &-sort-handle {
        cursor: move;
        margin-right: 0.35rem;
      }

      &-title {
        flex: 1;
      }

      &-item {
        &:hover {
          .construction-tpl-man__group-item-remove,
          .construction-tpl-man__group-item-edit {
            display: flex;
          }
        }

        @at-root {
          .construction-tpl-man__content--no-full-access {
            &:hover {
              .construction-tpl-man__group-item-remove,
              .construction-tpl-man__group-item-edit {
                display: none !important;
              }
            }
          }
        }

        &-remove,
        &-edit {
          display: none;
          position: absolute;
          right: -0.3rem;
          width: 1.2rem;
          height: 1.2rem;
          justify-content: center;
          align-items: center;
          border-radius: 1rem;
        }

        &-remove {
          color: #fff !important;
          background-color: red;
          top: -0.3rem;
          font-size: 0.75rem !important;
        }

        &-edit {
          color: #fff !important;
          background-color: #716aca;
          bottom: -0.3rem;
          font-size: 0.75rem !important;
        }
      }

      > h3 {
        font-size: 1.05rem;
        font-weight: bold;
        color: #716aca;
        border-bottom: 1px solid #ddd;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        svg {
          width: 18px;
          height: 18px;
          path {
            fill: #888;
          }
        }

        dl {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          font-weight: normal;
          font-size: 0.9rem;
          color: #555;
          margin: 0;

          dt {
            margin-right: 0.2rem;
            font-weight: normal;
          }

          dd {
            margin: 0;
            color: #999;

            &.construction-tpl-man__group-template-info-override {
              color: #e91e63;
            }
          }
        }
      }

      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 84px;

        > a {
          text-decoration: none;
          color: #aaa;
          &:hover {
            color: #716aca;
          }
        }
      }
    }
  }
}

.construction-tpl-man__group-item-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.construction-tpl-man__group-item,
.construction-tpl-man__group-item--being-dragged {
  cursor: move;
  padding: 0.35rem 1rem;
  border: 1px solid #777;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 80px;
  position: relative;

  > a {
    text-decoration: none;
    color: #333;
    font-size: 0.85rem;
  }

  > span {
    white-space: nowrap;
    color: #333;
    font-size: 0.85rem;
  }

  &--with-template {
    > span {
      font-weight: bold;
      color: #000;
    }
  }
}

.construction-tpl-picker {
  &__control {
    min-height: 40px !important;
  }
  &__value-container {
    height: 40px;
  }
  &__value-content {
    display: flex;
    flex-direction: row;
    > span {
      padding: 0.25rem 0.65rem;
      border: 1px solid #ddd;
      margin-right: 0.25rem;
      border-radius: 80px;
      color: #444;
      font-size: 0.85rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
    }
  }

  &__option {
    margin-bottom: 0 !important;

    > dt {
      font-weight: bold;
      font-size: 0.85rem;
    }
    > dd {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > span {
        padding: 0.25rem 0.65rem;
        border: 1px solid #ddd;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        border-radius: 80px;
        font-size: 0.9rem;
        color: #444;
      }
    }

    &--selected {
      > dd > span {
        color: #fff;
      }
    }
  }
}

.construction-tpl-man__category--being-dragged {
  list-style-type: none;
  margin: 0;
  .construction-tpl-man__category-list-item-remove {
    visibility: hidden;
  }
}

.construction-tpl-man__group-modal,
.construction-tpl-man__item-modal,
.construction-tpl-man__select-store-modal {
  dl {
    > dt {
      margin-bottom: 0.5rem;
    }
    > dd {
      margin-bottom: 0.5rem;
    }
  }
}

.construction-tpl-man__content--no-full-access {
  .construction-tpl-man__procedure-sort-handle,
  .construction-tpl-man__group-item,
  .construction-tpl-man__group-item--being-dragged {
    cursor: default !important;
  }
}

.construction-tpl-man__actions {
  .kt-switch {
    display: flex;
    margin-left: 0.5rem;
    > label {
      margin-bottom: 0;
    }
  }
}
