.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

html, body {
  &, .form-control, .btn, .btn.m-btn--custom {
    font-family: "Segoe UI", "Microsoft YaHei", Poppins;
  }
}