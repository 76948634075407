.kt-subheader {
  $select-height: 32px;
  $inner-height: $select-height - 8;

  .list-toolbar {
    margin-bottom: 0;
  }

  .toolbar-select {
    &__control {
      height: $select-height;
      min-height: 0 !important;
    }

    &__placeholder {
      line-height: $inner-height;
    }

    &__value-container {
      height: $select-height - 2 !important;
    }

    &__input {
      height: $inner-height - 4;
      position: relative;
      top: -2px;
    }

    &__indicators {
      height: $select-height;
    }
  }

  .form-control {
    height: $select-height;
  }
}