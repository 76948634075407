.kt-portlet .settings {
  &__page-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.settings-sidebar {
  width: 250px;
  padding-right: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 250px;
  h3 {
    font-size: 1.2rem;
    + p {
      font-size: 0.9rem;
    }
    > i {
      margin-right: 0.25rem;
    }
  }

  ul {
    margin-left: 0;
    padding: 0;
    li {
      list-style-type: none;
      margin-left: 0.5rem;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      a {
        font-size: 1.1rem;
        i > {
          font-size: 1.1rem;
        }
      }
    }
  }

  &__menu-item {
    > i {
      margin-right: 0.25rem;
    }
    &--active {
      border-bottom: 1px solid #5867dd;
      font-weight: bold;
    }
  }
}

.settings-editor {
  flex: 1;
  border-left: 1px solid #ddd;
  padding-left: 2rem;
  width: calc(100% - 250px);

  .form-group {
    &__label {
      font-weight: bold;
      font-size: 1.2rem;
      display: block;
      margin-bottom: 0.5rem;
    }

    .kt-datatable__empty {
      min-height: 10rem;
    }

    &__restricted-roles-for-delivery-check {
      .kt-checkbox-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .kt-checkbox {
          width: 30%;
        }
      }
    }
  }

  .entity-editor-form__element-group .form-group__label {
    font-size: 1rem;
    font-weight: normal;
  }

  .entity-editor-form__element-group > .form-group {
    margin-bottom: 0;
  }

  .form-group__help-text {
    margin-top: 0;
    margin-bottom: 1.2rem;
  }
}

.order-tags-editor {
  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > li {
      width: 30%;
      list-style-type: none;
      margin: 0.3rem 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .dropdown {
    .btn-sm {
      padding: 0.15rem 0.4rem;
    }
  }
}

.settings__extended-properties {
  &-list.kt-datatable {
    margin-bottom: 0;
  }

  &-empty {
    font-size: 0.9rem;
    font-style: italic;
    color: #777;
  }

  &-editor-aside {
    > .kt-block {
      display: flex;
      flex-direction: column;
      .kt-quick-panel__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
      }
    }
  }
}

.settings__property-editor {
  position: relative;
  flex: 1;

  .entity-editor-form {
    padding-bottom: 10rem !important;
  }

  .form-group {
    > label {
      font-weight: bold;
    }
  }

  .property-editor__option-item {
    > .kt-section {
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 1rem;
      > label {
        font-weight: normal;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .property-editor__attachment-editor {
    > .kt-section {
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 1rem;
      > label {
        font-weight: normal;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.property-editor__option-list-item,
.property-editor__attachment-list-item {
  .m-form__section {
    background-color: #f9f9f9;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    position: relative;
    > .kt-section {
      .form-group > label {
        display: block;
        cursor: move;
      }
      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--being-dragged {
    cursor: move;
    z-index: 100000;
    overflow: hidden;

    .m-form__section {
      background-color: #eee !important;
      margin-bottom: 0 !important;
      cursor: move;

      > .kt-section {
        margin-bottom: 0 !important;
        .form-group {
          margin-bottom: 1rem;
          cursor: move;
          > label {
            cursor: move;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .property-editor__option-item-close-button {
      display: none !important;
    }
  }
}

.property-editor__attachment-list-item {
  .drop-zone-preview > div,
  .drop-zone-preview > video {
    width: 120px !important;
    height: 120px !important;
  }
  .property-editor__attachment-metadata {
    flex-wrap: wrap;
  }
  .property-editor__attachment-type-visibility {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    > .form-group {
      flex: 1;
    }
  }
  .property-editor__attachment-type-visibility + .form-group {
    width: 100%;
  }
}

.entity-editor-form__element-group.app-settings-group {
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;

  > .form-group {
    margin-bottom: 0.25rem;

    &.order-summary-fields-visibility-settings {
      min-width: 100%;
      > label:first-child {
        display: block;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        color: #aaa;
      }
    }

    &.app-settings-group__hbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      > .form-control {
        width: auto !important;
        margin-left: 0.5rem;
      }
    }
  }
}

.entity-editor-form__element-group.product_settings_group {
  > .form-group {
    margin-right: 1rem;
    position: relative;

    &::after {
      content: '%';
      margin: 0 1rem 0 0.2rem;
      position: absolute;
      right: 0;
      top: 2.65rem;
    }

    .invalid-feedback ~ * {
      display: none;
    }

    &.validated::after {
      display: none;
    }
  }
}

.entity-editor-form__element-group.app_launch_setting_group {
  align-items: center;

  > .form-group {
    margin-right: 0;
  }

  > .form-group > .kt-checkbox {
    margin-bottom: 0;
  }

  > .form-group.app_launch_branding_field {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    > .form-group__label {
      white-space: nowrap;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }

    &::before {
      content: ',';
      margin-right: 0.2rem;
    }
  }
}

.property-editor__options-form-group {
  > label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #eee;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    font-size: 1.1rem;
  }
}
