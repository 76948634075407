@import './mixins';

$toolbar-item-standard-margin: 0.5em;
$toolbar-margin-bottom: 0px;

.standard-toolbar {
  display: flex;
  align-items: center;
  padding: 0 10px;
  > * {
    margin: 0 5px;
  }
  .form-control {
    display: inline-block;
    width: auto;
  }
}

.list-toolbar {
  $toolbar: &;

  margin-bottom: $toolbar-margin-bottom;

  @at-root {
    &__label {
      margin-right: 0.4em;
      margin-bottom: 0;
    }

    &__filter {
      @include flex-row-nowrap();
      display: flex;
      margin-right: $toolbar-item-standard-margin;

      &--right {
        justify-content: end;
        margin-right: 0;
        margin-left: $toolbar-item-standard-margin;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @at-root {
    &__right {
      .list-toolbar__filter {
        .toolbar__button {
          margin-left: 0 !important;
        }
      }
      .list-toolbar__filter:last-child {
        margin-right: 0;
      }
    }
  }
}