.quotation-tpl-man {
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__side-panel {
    > h2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__side-invalid-indicator {
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f44336;
    > i {
      font-size: 0.8rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .kt-switch {
      display: flex;
      margin-left: 0.5rem;
      > label {
        margin-bottom: 0;
      }
    }

    &-msg {
      color: #f44336;
      font-size: 0.85rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      i {
        margin-right: 0.25rem;
      }
    }
    .btn {
      margin-left: 1rem;
    }
  }
}
