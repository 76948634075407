@import './mixins';

.entity-editor-form__element-group {
  &--horizontal {
    @include flex-row-nowrap($justify: flex-start, $alignItems: flex-start);
    > .form-group {
      padding: 0;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--vertical {
    > .form-group {
      padding: 0;
      margin-bottom: 4px;
    }
  }
}

.kt-quick-panel__content {
  .alert {
    margin: 0 15px 15px;
  }

  .entity-editor-form {
    padding: 0 20px;
  }
}

.entity-editor-form {
  .form-group {
    .kt-checkbox-inline,
    .kt-radio-inline {
      display: inline-block;
      margin-left: 8px;
    }
  }

  .store-picker__component.store-picker__store__menu {
    margin-top: 4px !important;
  }

  .form-control-wrapper {
    position: relative;
  }
}
