@import 'dropdown';
@import 'button';
@import 'form';



.kt-subheader .kt-subheader__toolbar .btn-group .btn:not(.dropdown-toggle-split) {
  margin-left: 0;
  &:not(:first-child) {
    margin-left: -1px !important;
  }
}