@import '../../lib/metronic/assets/scss/config';
@import '../../lib/metronic/assets/scss/global/functions';

.weixin-authorization {
  padding: 20px;

  .kt-callout--diagonal-bg:after {
    left: 85%;
  }

  &__loading {
    min-height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .kt-spinner {
      margin-right: 28px;
    }
  }
}

.weixin-authorization-detail {
  .authorizer-info {
    .kt-widget__top {
      display: flex;
      align-items: flex-start;

      .kt-widget__media {
        margin-top: 0.2rem;
        img {
          width: 110px;
          border-radius: 8px;
        }
      }

      .kt-widget__pic {
        margin-top: 0.2rem;
        width: 100%;
        max-width: 110px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: 8px;
        @each $name, $value in $kt-state-colors {
          &--#{$name} {
            background: rgba(map-get($value, 'base'), 0.1);
          }
        }
      }

      .kt-widget__content {
        width: 100%;
        padding-left: 1.7rem;

        .kt-widget__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .kt-widget__user {
            display: flex;
            align-items: center;
          }

          .kt-widget__username {
            font-size: 1.3rem;
            color: #48465b;
            font-weight: 500;
            align-items: center;
            margin-right: 0.5rem;

            &:hover {
              color: #5d78ff;
              transition: color 0.3s ease;
            }

            i {
              font-size: 1.2rem;
              padding-left: 0.5rem;
              padding-right: 0.25rem;
            }
          }

          .kt-widget__title {
            font-size: 1.3rem;
            color: #48465b;
            font-weight: 600;
            margin: 0.8rem 0 0.7rem 0;

            &:hover {
              color: #5d78ff;
              transition: color 0.3s ease;
            }
          }

          .kt-widget__action {
            .btn {
              font-weight: 600;
              margin-left: 0.5rem;
            }
          }
        }

        .kt-widget__subhead {
          padding: 0.6rem 0 0.8rem 0;
          a {
            padding-right: 2rem;
            color: #74788d;
            font-weight: 500;

            &:hover {
              color: #5d78ff;
              transition: color 0.3s ease;
            }

            i {
              padding-right: 0.5rem;
            }
          }
        }

        .kt-widget__info {
          display: flex;
          flex-flow: row wrap;
          flex-direction: column;

          .kt-widget__desc {
            color: #595d6e;
            font-weight: 400;
            padding-right: 2rem;
            flex-grow: 1;
            margin-bottom: 0.5rem;
          }

          .kt-widget__progress {
            display: flex;
            align-items: center;
            max-width: 500px;
            width: 100%;
            margin: 0.1rem 0;

            .kt-widget__text {
              padding-right: 1rem;
              color: #595d6e;
              font-weight: 500;
            }

            .kt-widget__stats {
              padding-left: 1rem;
              color: #48465b;
              font-weight: 600;
              font-size: 1.1rem;
            }
          }

          .kt-widget__stats {
            margin-bottom: 0.7rem;
            display: flex;
            flex-wrap: wrap;

            .kt-widget__item {
              padding-top: 1rem;
              .kt-widget__date {
                color: #595d6e;
                font-weight: 500;
                padding-bottom: 1rem;
                display: block;
              }
              .kt-widget__subtitle {
                color: #595d6e;
                font-weight: 500;
                display: block;
              }
              .kt-widget__progress {
                width: 100%;
                margin: 1.4rem 0 0.5rem 0;

                .kt-widget__stat {
                  padding-left: 0.7rem;
                  color: #48465b;
                  font-weight: 600;
                }
              }

              &:not(:first-child):not(:last-child) {
                margin: 0 2.2rem;
              }

              &:last-child {
                padding-left: 1rem;
              }
            }
          }
        }
      }
    }

    .kt-widget__bottom {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      border-top: 1px solid #ebedf2;
      margin-top: 2rem;
    }
  }

  .authorization-info {
    display: block;
  }

  &__permission-set {
    width: 100%;
    border-right: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
  }

  &__permission {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 32px;
    border-bottom: 1px solid #eee;

    > dt {
      margin: 0;
      padding: 0;
      font-weight: normal;
      border-left: 1px solid #eee;
      padding-left: 10px;
    }

    > dd {
      width: 80px;
      text-align: center;
      margin: 0;
      padding: 0;
      border-left: 1px solid #eee;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.weixin-authorization__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;

  .mpln-state-ct {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    > i {
      font-size: 1.3rem;
      margin-right: 4px;
    }
  }

  .mpln-state {
    margin-right: 8px;
    > i {
      font-size: 1.1rem;
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .mpln-state--error {
    color: #fd397a;
  }

  .mpln-state--linked {
    color: #0abb87;
  }
}
